import { ApolloClient } from 'apollo-client';
import * as Font from 'expo-font';
import React, { useCallback, useEffect, useState } from 'react';
import { Platform, StyleSheet, useWindowDimensions, View } from 'react-native';
import * as Sentry from '@sentry/browser';
import { useImmer } from 'use-immer';
import client from '../client/apolloClient';
import screenSizes from '../configs/screenSizes';
import { Router } from '../utils/routing';
import Alert, { AlertTypes } from './Alert';
import ApolloProvider from './ApolloProvider';
import ErrorBoundary from './ErrorBoundary';
import LoadingView from './LoadingView';
import env from '@beam-australia/react-env';

  Sentry.init({
    dsn: "https://e60f4a48b6e32b125c245307c53b2319@o1310286.ingest.us.sentry.io/4507974683525120",
    release: env('APP_VERSION'),
    environment: env('ENVIRONMENT'),
    debug: true,
  });


if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    exclude: [
      /^TouchableOpacity/,
      /^TouchableHighlight/,
      /^TouchableWithoutFeedback/,
    ],
  });
}

const startServiceWorker = async (
  createUIPrompt: (props: {
    onAccept: () => void;
    onReject: () => void;
  }) => { dismiss: () => void },
) => {
  if ('serviceWorker' in navigator) {
    const { Workbox, messageSW } = await import('workbox-window');

    const wb = new Workbox('/service-worker.js');

    let registration;

    wb.addEventListener('activated', (event) => {
      if (!event.isUpdate) {
        console.log('Service worker activated!');
      }
      if (event.isUpdate) {
        console.log('Service worker is updating');
      }
    });

    const showSkipWaitingPrompt = (event) => {
      const prompt = createUIPrompt({
        onAccept: async () => {
          wb.addEventListener('controlling', (event) => {
            window.location.reload();
          });

          if (registration && registration.waiting) {
            messageSW(registration.waiting, { type: 'SKIP_WAITING' });
            prompt?.dismiss();
          }
        },

        onReject: () => {
          prompt?.dismiss();
        },
      });
    };

    wb.addEventListener('waiting', showSkipWaitingPrompt);
    // @ts-ignore
    wb.addEventListener('externalwaiting', showSkipWaitingPrompt);

    wb.addEventListener('installing', (event) => {
      console.log(`Service worker installing`);
    });

    wb.addEventListener('installed', (event) => {
      console.log(`Service worker installed`);
    });

    wb.register().then((r) => (registration = r));
  }
};

const App: React.FunctionComponent = () => {
  const [fontsLoaded, setFontsLoaded] = useState(Platform.OS === 'web');
  const [apolloClient, setApolloClient] = useState<
    ApolloClient<any> | undefined
  >();

  const { width } = useWindowDimensions();

  const [promptState, setPromptState] = useImmer({
    onAccept: () => console.log('accepted'),
    onReject: () => null,
    show: false,
  });

  const createUIPrompt = useCallback(
    (props: { onAccept: () => void; onReject: () => void }) => {
      setPromptState((f) => {
        f.onAccept = props.onAccept;
        f.onReject = props.onReject;
        f.show = true;
      });

      return {
        dismiss: () => {
          setPromptState((f) => {
            f.show = false;
          });
        },
      };
    },
    [],
  );

  useEffect(() => {
    if (Platform.OS !== 'web') {
      Font.loadAsync({
        'Quicksand-Regular': require('../../assets/fonts/Quicksand-Regular.ttf'),
        'Quicksand-Medium': require('../../assets/fonts/Quicksand-Medium.ttf'),
        'Quicksand-Bold': require('../../assets/fonts/Quicksand-Bold.ttf'),
        'Quicksand-Light': require('../../assets/fonts/Quicksand-Light.ttf'),
      }).then(() => {
        setFontsLoaded(true);
      });
    }

    if (
      Platform.OS === 'web' &&
      !!env('ENVIRONMENT') &&
      env('ENVIRONMENT') !== 'development'
    ) {
      startServiceWorker(createUIPrompt);
    }

    const waitClient = async () => {
      const cli = await client();
      setApolloClient(cli);
    };
    waitClient();
  }, []);

  return fontsLoaded && apolloClient ? (
    <ErrorBoundary>
      <Router>
        <ApolloProvider client={apolloClient} />
      </Router>
      {promptState.show && (
        <View
          style={[
            AppStyle.alertsWrap,
            {
              left: width > screenSizes.medium ? width / 2 - 250 : 10,
              width: width > screenSizes.medium ? 500 : width - 20,
            },
          ]}
        >
          <Alert
            removeAlert={() =>
              setPromptState((f) => {
                f.show = false;
              })
            }
            hash={123}
            duration={0}
            onClose={promptState.onReject}
            onPress={promptState.onAccept}
            type={AlertTypes.INFO}
            text="a new version of transparently is available, click here to reload"
          />
        </View>
      )}
    </ErrorBoundary>
  ) : (
    <View style={[AppStyle.loader]}>
      <LoadingView />
    </View>
  );
};

export default App;

const AppStyle = StyleSheet.create({
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loaderText: {
    fontSize: 16,
  },
  alertsWrap: {
    position: 'absolute',
    width: 500,
    top: 120,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
