import env from '@beam-australia/react-env';
import AsyncStorage from '@react-native-community/async-storage';
import { Platform } from 'react-native';
import { SubscriptionClient } from 'subscriptions-transport-ws';

export const replaceAndroid = (str: string = '') =>
  str?.indexOf('localhost') > -1 && Platform.OS === 'android'
    ? str?.replace('localhost', '10.0.2.2')
    : str;

// get the authentication token from local storage if it exists
export const getToken = () => AsyncStorage.getItem('token');

export const wsURL = replaceAndroid(env('WS_URL'));
export const subscriptionClient = new SubscriptionClient(wsURL, {
  lazy: true,
  reconnect: true,
  connectionParams: async () => {
    const token = await getToken();
    return {
      authToken: token,
    };
  },
});
